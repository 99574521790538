<template>
  <div v-loading="loading">
    <div class="maintitle">{{ $route.meta.title }}</div>
    <el-divider></el-divider>
    <el-row class="text-left" v-if="terminal=='pc'">
      <el-col :span='6'>
        <el-input v-model="keyword" placeholder="请输入搜索关键词" size="mini" @keyup.enter.native="srchRecord"></el-input>
      </el-col>
      <el-cascader v-model="crntType" :options="typeList" size="mini" style="width:300px" placeholder="请选择工作类型" class="margin-left" clearable :props="{ checkStrictly: true }"></el-cascader>
      <!-- <el-select v-model="crntType" placeholder="请选择业务类型" size="mini" class="margin-left" clearable>
        <el-option v-for="(item, index) in typeList" :key="index" :label="item.name" :value="item.id"></el-option>
      </el-select> -->
      <el-select v-model="crntKefu" placeholder="请选择客服" size="mini" class="margin-left" clearable>
        <el-option v-for="(item, index) in kefuList" :key="index" :label="item.name" :value="item.id"></el-option>
      </el-select>
      <el-select v-model="crntStatus" placeholder="请选择工单状态" size="mini" class="margin-left" clearable @change="list=[];getlist()">
        <el-option label="待制作" :value="1"></el-option>
        <el-option label="制作中" :value="2"></el-option>
        <el-option label="已完成" :value="3"></el-option>
        <el-option label="已转出" :value="4"></el-option>
        <el-option label="已交付" :value="5"></el-option>
      </el-select>
      <el-select v-model="crntSort" placeholder="请选择排序方式" size="mini" class="margin-left" clearable>
        <el-option v-for="(item, index) in sortList" :key="index" :label="item.title" :value="item.id"></el-option>
      </el-select>
      <el-button class="margin-left" size="mini" @click="srchRecord">查找</el-button>
      <el-button type="success" size="mini" @click="addTask">添加工单</el-button>
    </el-row>
    <div v-else>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-input v-model="keyword" placeholder="请输入搜索关键词" size="mini" @keyup.enter.native="srchRecord"></el-input>
        </el-col>
        <el-col :span=12>
          <el-cascader v-model="crntType" :options="typeList" style="width:100%" size="mini" placeholder="请选择工作类型" clearable :props="{ checkStrictly: true }"></el-cascader>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-select v-model="crntKefu" placeholder="请选择客服" size="mini" clearable style="width:100%">
            <el-option v-for="(item, index) in kefuList" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-col>
        <el-col :span=12>
          <el-select v-model="crntStatus" placeholder="请选择工单状态" size="mini" style="width:100%" clearable @change="list=[];getlist()">
            <el-option label="待制作" :value="1"></el-option>
            <el-option label="制作中" :value="2"></el-option>
            <el-option label="已完成" :value="3"></el-option>
            <el-option label="已转出" :value="4"></el-option>
            <el-option label="已交付" :value="5"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-select v-model="crntSort" placeholder="请选择排序方式" style="width:100%" size="mini" clearable>
            <el-option v-for="(item, index) in sortList" :key="index" :label="item.title" :value="item.id"></el-option>
          </el-select>
        </el-col>
        <el-col :span=6>
          <el-button size="mini" style="width:100%" @click="srchRecord">查找</el-button>
        </el-col>
        <el-col :span="6">
          <el-button type="success" style="width:100%" size="mini" @click="addTask">添加工单</el-button>
        </el-col>
      </el-row>
    </div>
    <el-table :data="list" border>
      <el-table-column label="客户名称" prop="company" fixed></el-table-column>
      <el-table-column prop="type_text" label="业务类型" width="160"></el-table-column>
      <el-table-column prop="time" label="工单下达日期" width="120"></el-table-column>
      <el-table-column prop="yewu" label="业务" width="160"></el-table-column>
      <el-table-column prop="kefu" label="客服" width="100"></el-table-column>
      <el-table-column label="状态" :formatter="formatStatus" width="100"></el-table-column>
      <el-table-column label="总制作量" width="100">
        <template slot-scope="scope">
          {{ scope.row.total }}{{ scope.row.unit }}
        </template>
      </el-table-column>
      <el-table-column label="当前进度" width="100">
        <template slot-scope="scope">
          {{ scope.row.schedule }}{{ scope.row.unit }}
        </template>
      </el-table-column>
      <el-table-column label="待制作量" v-if="crntStatus==2">
        <template slot-scope="scope">
          {{ scope.row.crntnum }}{{ scope.row.unit }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width='300'>
        <template slot-scope="scope">
          <el-button type='text' @click="addLogs(scope.row.id, 0)">添加备注</el-button>
          <el-button type='text' @click="showLogs(scope.row.id)">查看备注</el-button>
          <el-button type='text' @click="addLogs(scope.row.id, 1)">转出</el-button>
          <el-button type='text' @click="mdfy(scope.row.id)">修改</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination v-if="terminal=='pc'" background :current-page="page" :page-sizes="pagesizes" :page-size="pagesize" @size-change="handleSizeChange" @current-change="handleCurrentChange" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    <el-pagination v-else background :current-page="page" :page-sizes="pagesizes" :page-size="pagesize"  @current-change="handleCurrentChange" layout="total, prev, pager, next" :total="total"></el-pagination>
    <el-dialog :visible.sync="infoDiv" :show-close="false" :close-on-click-modal="false" @closed="resetInfo" title="请输入备注详情">
      <el-form :model="info">
        <el-form-item label="日期" :label-width="formLabelWidth" class="text-left">
          <el-date-picker v-model="info.time" type="date" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="内容" :label-width="formLabelWidth">
          <el-input type="textarea" :rows="3" v-model="info.message" auto-complete="off" maxlength="70" show-word-limit @keyup.enter.native="submit"></el-input>
        </el-form-item>
        <el-form-item label="图片" :label-width="formLabelWidth" class="text-left">
          <el-upload style='align-self:center' :action="HOST + '/index/uploadFile?name=taskpic'" accept="jpg/jpeg/png" name="taskpic" :data="uploadData" :auto-upload="true" :on-success="showRes" :before-upload='showloading' class="margin-bottom" list-type="picture-card" :on-remove="handleRemove"
            v-if="info.pic.length < 5">
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="infoDiv = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="addDiv" :show-close="false" :close-on-click-modal="false" @closed="resetTaskInfo" width="80%">
      <el-form :model="taskInfo" :label-position="terminal=='pc'?'right':'top'" class="text-left">
        <el-form-item label="日期" :label-width="formLabelWidth" class="text-left">
          <el-date-picker v-model="taskInfo.time" type="date" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="客户名称" :label-width="formLabelWidth" class="text-left">
          <el-select v-model="taskInfo.companyid" placeholder="请选择" filterable allow-create default-first-option style="width:300px">
            <el-option v-for="(item,index) in companyList" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="业务类型" :label-width="formLabelWidth" class="text-left">
          <el-select v-model="taskInfo.typeid" placeholder="请选择" filterable allow-create default-first-option>
            <el-option v-for="(item,index) in typeList" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="子业务类型" :label-width="formLabelWidth" class="text-left" v-if="taskInfo.typeid!=''">
          <el-select v-model="taskInfo.childtypeid" placeholder="请选择" filterable allow-create default-first-option>
            <el-option v-for="(item,index) in childtypeList" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" :label-width="formLabelWidth" class="text-left" @change="crntnum=0">
          <el-select v-model="taskInfo.status" placeholder="请选择工单状态">
            <el-option label="待制作" :value="1"></el-option>
            <el-option label="制作中" :value="2"></el-option>
            <el-option label="已完成" :value="3"></el-option>
            <el-option label="已转出" :value="4"></el-option>
            <el-option label="已交付" :value="5"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="可制作数量" :label-width="formLabelWidth" v-if="taskInfo.status == 2">
          <el-input v-model="taskInfo.crntnum" placeholder="请输入当前可制作数量"></el-input>
        </el-form-item>
        <el-form-item label="日期" :label-width="formLabelWidth" class="text-left" v-if="taskInfo.status==5">
          <el-date-picker v-model="taskInfo.finishtime" type="date" placeholder="选择交付日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="客服" :label-width="formLabelWidth" class="text-left">
          <el-select v-model="taskInfo.kefuid" placeholder="请选择" filterable allow-create default-first-option>
            <el-option v-for="(item,index) in kefuList" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="业务" :label-width="formLabelWidth" class="text-left">
          <el-select v-model="taskInfo.yewuid" placeholder="请选择" filterable allow-create default-first-option multiple>
            <el-option v-for="(item,index) in yewuList" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="工作量单位" :label-width="formLabelWidth" v-if="isNaN(taskInfo.childtypeid)">
          <el-input v-model="taskInfo.unit" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="单价" :label-width="formLabelWidth">
          <el-input v-model="taskInfo.price" auto-complete="off" type="number">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item label="总工作量" :label-width="formLabelWidth">
          <el-input v-model="taskInfo.total" auto-complete="off" type="number">
            <template slot="append">{{ typeUnit }}</template>
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addDiv = false">取 消</el-button>
        <el-button type="primary" @click="submitTask">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="logsDiv" :show-close="false" :close-on-click-modal="false">
      <el-table :data="logsList" border>
        <el-table-column prop="time" label="日期" width="120"></el-table-column>
        <el-table-column label="详情">
          <template slot-scope="scope">
            <span v-html="scope.row.info"></span>
          </template>
        </el-table-column>
        <el-table-column label="附件">
          <template slot-scope="scope">
            <el-image v-for="(item, index) in scope.row.pic" :key="index" :src="$pic(item)" alt="" :preview-src-list="$picList(scope.row.pic)" style="width:100px;cursor: pointer;"></el-image>
            <!-- <img v-for="(item, index) in scope.row.pic" :key="index" :src="picHost + item" alt="" @click="showPic(item)" style="width:100px;cursor: pointer;"> -->
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="logsDiv = false">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      typeList: [],
      keyword: "",
      page: sessionStorage.getItem("taskpage")
        ? parseInt(sessionStorage.getItem("taskpage"))
        : 1,
      total: 0,
      crntType: "",
      crntStatus: "",
      crntid: "",
      crntact: "",
      infoDiv: false,
      info: {
        message: "",
        time: this.getDate(),
        pic: [],
      },
      taskInfo: {
        id: "",
        companyid: "",
        typeid: "",
        kefuid: "",
        yewuid: [],
        childtypeid: "",
        price: "",
        total: "",
        crntnum: 0,
        status: 1,
        time: this.getDate(),
      },
      tempInfo: {},
      loading: false,
      logsList: [],
      logsDiv: false,
      crntKefu: "",
      crntSort: "",
      sortList: [],
      companyList: [],
      kefuList: [],
      yewuList: [],
      addDiv: false,
      terminal: this.getTerminal(),
      uploadData: {
        token: sessionStorage.getItem("token"),
      },
    };
  },
  methods: {
    setPage() {
      this.page = parseInt(sessionStorage.getItem("taskpage"));
      this.getlist();
    },
    showloading() {
      this.loading = true;
    },
    handleSizeChange(size) {
      this.pagesize = size;
      this.page = 1;
      this.getlist();
    },
    handleCurrentChange(p) {
      this.page = p;
      sessionStorage.setItem("taskpage", p);
      this.getlist();
    },
    handleRemove(file, fileList) {
      this.$axios({
        url: this.HOST + "/index/delPic",
        method: "post",
        data: {
          pic: file.response.file,
        },
      }).then((res) => {
        if (res.status == 1) {
          this.info.pic.splice(this.info.pic.indexOf(file.response.file), 1);
        }
      });
    },
    getlist() {
      this.$axios({
        url: "/task/list",
        data: {
          keyword: this.keyword,
          type: this.crntType,
          status: this.crntStatus,
          page: this.page,
          pagesize: this.pagesize,
          sort: this.crntSort,
          kefu: this.crntKefu,
        },
      }).then((res) => {
        if (res.status == 1) {
          this.list = res.list.data;
          this.total = res.list.total;
        }
      });
    },
    formatStatus(c, a) {
      switch (c.status) {
        case 1:
          return "待制作";
        case 2:
          return "制作中";
        case 3:
          return "已完成";
        case 4:
          return "已转出";
        case 5:
          return "已交付";
      }
    },
    // gettypeList() {
    //   this.$axios({
    //     url: this.HOST + "/index/gettypeList",
    //     method: "post",
    //     data: {
    //       id: 0,
    //     },
    //   }).then((res) => {
    //     if (res.status == 1) {
    //       this.typeList = res.list;
    //     }
    //   });
    // },
    srchRecord() {
      this.page = 1;
      this.getlist();
    },
    addTask() {
      // this.$router.push("/task/add");
      this.addDiv = true;
    },
    addLogs(id, type) {
      this.crntid = id;
      this.crntact = type;
      this.infoDiv = true;
    },
    resetInfo() {
      this.info.message = "";
      this.info.time = this.getDate();
    },
    resetTaskInfo() {
      for (let item in this.tempInfo) {
        this.taskInfo[item] = this.tempInfo[item];
      }
    },
    submit() {
      this.loading = true;
      this.$axios({
        url: this.HOST + "/task/addLogs",
        method: "post",
        data: {
          info: this.info,
          type: this.crntact,
          id: this.crntid,
        },
      }).then((res) => {
        this.loading = false;
        if (res.status == 1) {
          this.$message({
            type: "success",
            message: res.info,
          });
          this.infoDiv = false;
        }
      });
    },
    submitTask() {
      this.$axios({
        url: this.HOST + "/task/add",
        method: "post",
        data: {
          info: this.taskInfo,
        },
      }).then((res) => {
        if (res.status == 1) {
          this.$message({
            type: "success",
            message: res.info,
          });
          this.addDiv = false;
          this.getlist();
        }
      });
    },
    showLogs(id) {
      this.loading = true;
      this.$axios({
        url: this.HOST + "/task/getLogs",
        method: "post",
        data: {
          id: id,
        },
      }).then((res) => {
        this.loading = false;
        if (res.status == 1) {
          this.logsList = res.list;
          this.logsDiv = true;
        }
      });
    },
    mdfy(id) {
      // this.$router.push("/task/add/" + id);
      this.$axios({
        url: this.HOST + "/task/getInfo",
        method: "post",
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.status == 1) {
          this.saveInfo("taskInfo");
          this.taskInfo = res.info;
          this.addDiv = true;
        }
      });
    },
    showRes(res) {
      this.loading = false;
      if (res.status == 1) {
        this.info.pic.push(res.file);
      } else {
        this.$message({
          type: "error",
          message: res.info,
        });
      }
    },
  },
  computed: {
    childtypeList() {
      for (let item in this.typeList) {
        if (this.typeList[item].value == this.taskInfo.typeid)
          return this.typeList[item].children;
      }
    },
    typeUnit() {
      for (let item in this.childtypeList) {
        if (this.childtypeList[item].value == this.taskInfo.childtypeid)
          return this.childtypeList[item].unit;
      }
    },
  },
  created: function () {
    this.getCommonInfo([
      "kefuList",
      "sortList",
      "typeList",
      "companyList",
      "yewuList",
    ]);
    this.getlist();
  },
};
</script>
<style>
.toplevel {
  width: 100%;
  font-weight: bold;
}

.toplevel > .el-checkbox__label {
  font-size: 16px;
}
</style>