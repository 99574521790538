<!--
 * @description: 
 * @Author: HaoJG
 * @Date: 2021-08-09 10:49:28
 * @Lasteditors: HaoJG
 * @LastEditTime: 2022-03-02 15:55:33
-->
<template>
  <div>
    <div class="maintitle">{{$route.meta.title}}</div>
    <el-divider></el-divider>
    <div v-for='(item,index) in info' :key='index'>
      <el-row :gutter="20" v-if="item.type == 'input'" class="margin-bottom">
        <el-col :span="6" class="text-right">{{item.title}}</el-col>
        <el-col :span="18" class="text-left">
          <el-input size="mini" v-model="item.detail" :placeholder="item.beizhu">
            <template slot="prepend" v-if="item.prepend!=''">{{item.prepend}}</template>
            <template slot="append" v-if="item.append!=''">{{item.append}}</template>
          </el-input>
        </el-col>
      </el-row>
      <el-row :gutter="20" v-if="item.type == 'textarea'" class="margin-bottom">
        <el-col :span="6" class="text-right">{{item.title}}</el-col>
        <el-col :span="18" class="text-left">
          <el-input size="mini" v-model="item.detail" type="textarea" :placeholder="item.beizhu"></el-input>
        </el-col>
      </el-row>
      <el-row :gutter="20" v-if="item.type=='radio'" class="margin-bottom">
        <el-col :span="6" class="text-right">{{item.title}}</el-col>
        <el-col :span="18" class="text-left">
          <el-radio-group v-model="item.detail" size="mini">
            <el-radio-button v-for="(i,k) in item.choice" :key='k' :label="i.title">{{i.info}}</el-radio-button>
          </el-radio-group>
        </el-col>
      </el-row>
      <el-row :gutter="20" v-if="item.type=='select'" class="margin-bottom">
        <el-col :span="6" class="text-right">{{item.title}}</el-col>
        <el-col :span="18" class="text-left">
          <el-select v-model="item.detail" placeholder="请选择" size='mini'>
            <el-option v-for="(i, k) in item.choice" :key="k" :label="i.info" :value="i.title"></el-option>
          </el-select>
        </el-col>
      </el-row>
    </div>
    <el-button type="primary" @click="mdfysetting">修改</el-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: {}, //参数信息
    };
  },
  methods: {
    getsetting() {
      this.$axios({
        url: this.HOST + "/setting/getSetting",
        method: "post",
        data: {},
      }).then((res) => {
        if (res.status == 1) {
          this.info = res.info;
        }
      });
    },
    mdfysetting() {
      this.$confirm(
        "确定要修改配置吗？部分配置会影响到在线用户，建议在用户访问少的时候进行修改",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        this.$axios({
          url: this.HOST + "/setting/saveSetting",
          method: "post",
          data: {
            info: this.info,
          },
        }).then((res) => {
          if (res.status == 1) {
            this.getsetting();
            this.$message({
              showClose: true,
              type: "info",
              message: "操作成功",
            });
          }
        });
      });
    },
  },
  created: function () {
    this.getsetting();
  },
};
</script>