<template>
  <div id="logindiv" class="pclogin">
    <div id="login">
      <h3 style="margin-bottom:20px">
        <span>{{ webname }}</span>
      </h3>
      <el-row class="margin-large-bottom">
        <el-input prefix-icon="el-icon-user" type="text" v-model="tel" placeholder="请输入账号或手机号码" />
      </el-row>
      <el-row class="margin-large-bottom">
        <el-input prefix-icon="el-icon-key" type="password" v-model="password" placeholder="请输入密码" @keyup.enter.native="submitForm" />
      </el-row>
      <el-row class="margin-large-bottom text-center">
        <el-button type="primary" @click="submitForm">登录</el-button>
      </el-row>
    </div>
    <div class="text-center" style="position:absolute;bottom:0;width:100%;">
      <a :href="beianurl" class="margin-right" :style="beiancolor_text" v-if="beian!=''">{{beian}}</a><a :href="gonganbeianurl" :style="beiancolor_text" v-if="gonganbeian!=''"><img v-if="gonganico!=''" height="20px" :src="gonganico">{{gonganbeian}}</a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tel: "",
      password: "",
      beian: '',
      beianurl: '',
      gonganbeian: '',
      gonganbeianurl: '',
      gonganico: '',
      beiancolor: '#fff',
      webname: window.webName,
    };
  },
  mounted() {
    // this.request();
  },
  computed: {
    beiancolor_text() {
      return "color:" + this.beiancolor;
    }
  },
  methods: {
    getinfo() {
      this.$axios({
        url: '/index/getinfo',
      }).then(res => {
        if (res.status == 1) {
          this.webname = res.webname;
          sessionStorage.setItem('webname', res.webname);
          this.beian = res.beian;
          this.beianurl = res.url;
          this.gonganbeian = res.gonganbeian;
          this.gonganbeianurl = res.gonganbeian_url;
          this.gonganico = res.gongan_ico;
          this.beiancolor = res.beiancolor;
        }
      });
    },
    submitForm() {
      if (this.tel == "") {
        this.$message({
          showClose: true,
          type: "error",
          message: "请输入手机号或账号",
        });
        return;
      }
      if (this.password == "") {
        this.$message({
          showClose: true,
          type: "error",
          message: "请输入密码",
        });
        return;
      }
      this.$axios({
        method: "post",
        url: this.HOST + "/login/login",
        data: {
          account: this.tel,
          password: this.password,
        },
      }).then((res) => {
        // console.log(res);
        if (res.status == 1) {
          sessionStorage.setItem("name", res.adminname);
          sessionStorage.setItem("token", res.token);
          sessionStorage.setItem("islogin", true);
          sessionStorage.setItem("menulist", JSON.stringify(res.menulist));
          // window.location.reload(true);
          this.$router.push('/' + res.menulist[0]['children'][0]['controller'] + '/' + res.menulist[0]['children'][0]['action']);
        }
      });
    },
  },
  created() {
    this.getinfo();
  }
};
</script>

<style>
.puzzle-box {
  box-sizing: border-box;
}

.puzzle-container {
  box-sizing: border-box;
  width: 100%;
  display: inline-block !important;
}

.title {
  padding-top: 50px;
  margin-bottom: 20px;
}
</style>
