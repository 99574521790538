<template>
  <div>
    <div class="maintitle">管理员列表</div>
    <el-divider></el-divider>
    <el-row class="text-left" v-if="terminal=='pc'">
      <el-col :span='6'>
        <el-input v-model="keyword" placeholder="请输入搜索关键词" size="mini"></el-input>
      </el-col>
      <el-button class="margin-left" size="mini" @click="srchOperator">查找</el-button>
      <el-button type="success" size="mini" @click="addDiv=true">添加管理员</el-button>
    </el-row>
    <div v-else>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-input v-model="keyword" placeholder="请输入搜索关键词" size="mini"></el-input>
        </el-col>
        <el-col :span=6>
          <el-button size="mini" style="width:100%" @click="srchOperator">查找</el-button>
        </el-col>
        <el-col :span="6">
          <el-button type="success" style="width:100%" size="mini" @click="addDiv=true">添加管理员</el-button>
        </el-col>
      </el-row>
    </div>
    <el-table :data="list" row-key="menuid" border>
      <el-table-column prop="account" label="账号"></el-table-column>
      <el-table-column prop="tel" label="电话"></el-table-column>
      <el-table-column prop="name" label="姓名"></el-table-column>
      <el-table-column prop="group_name" label="用户组"></el-table-column>
      <el-table-column label="状态" :formatter="formatStatus" width='80'></el-table-column>
      <el-table-column prop="addtime" label="添加时间" width='160'></el-table-column>
      <el-table-column prop="logintime" label="最后登录时间"></el-table-column>
      <el-table-column prop="lastip" label="最后登录ip"></el-table-column>
      <el-table-column label="操作" width='200'>
        <template slot-scope="scope">
          <el-button type='text' @click="mdfy(scope)">修改</el-button>
          <el-button type='text' @click="setStatus(scope)" class="margin-small-right">{{scope.row.status==1?'停用':'启用'}}</el-button>
          <el-button type='text' @click="resetpswd(scope)" class="margin-small-right">重置密码</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :visible.sync="addDiv" :show-close="false" :close-on-click-modal="false" @opened="initinfo" @closed="resetFormInfo('operatorinfo')">
      <el-form :model="operatorinfo" :rules="rules" ref="operatorinfo" :label-position="terminal=='pc'?'right':'top'" class="text-left">
        <el-form-item label="用户组" :label-width="formLabelWidth" class="text-left" prop="group">
          <el-select v-model="operatorinfo.group" placeholder="请选择">
            <el-option v-for="(item,index) in groups" :key="index" :label="item.title" :value="item.groupid"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="账号" :label-width="formLabelWidth" prop="account" v-if="accounttype!=2">
          <el-input v-model="operatorinfo.account" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="电话" :label-width="formLabelWidth" prop="tel" v-if="accounttype!=1">
          <el-input v-model="operatorinfo.tel" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="姓名" :label-width="formLabelWidth" prop="name">
          <el-input v-model="operatorinfo.name" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item :label-width="formLabelWidth" label="状态" class="text-left" prop="status">
          <el-radio-group v-model="operatorinfo.status">
            <el-radio-button label="1">正常</el-radio-button>
            <el-radio-button label="0">停用</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="operatorinfo.uuid==''">
          新用户默认密码：{{dfltpswd}}
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addDiv = false">取 消</el-button>
        <el-button type="primary" @click="submdfy">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [], //管理员列表
      groups: [], //用户组列表
      menus: {}, //菜单列表
      keyword: '',
      addDiv: false,
      operatorinfo: {
        uuid: "",
        account: "",
        tel: "",
        name: "",
        group: '',
        company: '',
        status: 1
      },
      crntscope: "",
      usegroup: false,
      accounttype: 3,
      dfltpswd: '',
      companyList: [],
      terminal: this.getTerminal(),
      rules: {
        // title: [
        //   { required: true, message: "请输入菜单名称", trigger: "blur" },
        //   { min: 2, message: "长度不能小于两个汉字", trigger: "blur" }
        // ],
        // model: [{ required: true, message: "请输入模块名称", trigger: "blur" }],
        // controller: [
        //   { required: true, message: "请输入控制器名称", trigger: "blur" }
        // ],
        // action: [{ required: true, message: "请输入方法名称", trigger: "blur" }]
      }
    };
  },
  methods: {
    getlist() {
      this.$axios({
        url: this.HOST + "/operator/getlist",
        method: "post",
        data: {
          keyword: this.keyword
        }
      }).then(res => {
        if (res.status == 1) {
          this.list = res.admins;
          this.groups = res.groups ? res.groups : [];
          this.accounttype = res.accounttype;
          this.dfltpswd = res.dfltpswd;
          this.companyList = res.companyList;
        }
      });
    },
    formatStatus(row, column) {
      return row.status == 1 ? "正常" : "停用";
    },
    formathidden(row, column) {
      return row.isshow == 1 ? "正常" : "隐藏";
    },
    setStatus(scope) {
      let status = scope.row.status == 1 ? 0 : 1;
      this.$axios({
        url: this.HOST + "/operator/setStatus",
        method: "post",
        data: {
          info: {
            uuid: scope.row.uuid,
            status: status
          }
        }
      }).then(res => {
        if (res.status == 1) {
          scope.row.status = status;
        }
      });
    },
    //重置菜单编辑对话框内容
    resetFormInfo(formName) {
      this.$refs[formName].resetFields();
      this.crntscope = "";
    },
    mdfy(scope) {
      this.crntscope = scope;
      this.addDiv = true;
    },
    initinfo() {
      if (this.crntscope != "") {
        for (let item in this.operatorinfo) {
          this.operatorinfo[item] = this.crntscope.row[item];
        }
      }
    },
    submdfy() {
      this.$axios({
        url: this.HOST + "/operator/add",
        method: "post",
        data: {
          info: this.operatorinfo
        }
      }).then(res => {
        if (res.status == 1) {
          this.addDiv = false;
          this.getlist();
        }
      });
    },
    resetpswd(scope) {
      let uuid = scope.row.uuid;
      this.$confirm(
        "确定要重置" + scope.row.name + "的密码吗？本操作不可恢复",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        this.$axios({
          url: this.HOST + "/operator/resetpswd",
          method: "post",
          data: {
            muuid: uuid,
          },
        }).then((res) => {
          if (res.status == 1) {
            this.$alert(
              "用户密码已重置为 " +
              res.newpassword +
              " ，请记录后再关闭本对话框，本密码不可重现！",
              "友情提示",
              {
                confirmButtonText: "确定",
              }
            );
          }
        });
      });
    },
    srchOperator() {
      this.page = 1;
      this.getlist();
    },
  },
  created: function () {
    this.getlist();
  }
};
</script>
<style>
.toplevel {
  width: 100%;
  font-weight: bold;
}
.toplevel > .el-checkbox__label {
  font-size: 16px;
}
</style>