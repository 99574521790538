<!--
 * @description: 
 * @Author: HaoJG
 * @Date: 2021-08-09 10:49:28
 * @Lasteditors: HaoJG
 * @LastEditTime: 2022-03-02 15:55:33
-->
<template>
  <div>
    <div class="maintitle">{{$route.meta.title}}</div>
    <el-divider></el-divider>
    <div v-loading="loading">
      <div class="text-left margin-bottom" style="color:#999">友情提示：鼠标移到banner图上时，右上角会出现删除图标，可以删除该banner图；可以拖动图片调整显示顺序；修改完成后，需要点击保存按钮进行保存</div>
      <div class="piclistDiv" v-if="bannerList.length>0">
        <draggable v-model='bannerList' group="banner" @start="drag=true" @end="drag=false" style='flex-grow:1;display:flex;border: 1px solid #ddd;margin-left: 10px;border-radius: 10px;flex-direction: column;'>
          <li v-for="(item, i) in bannerList" :index="i" :key="i" style="display:flex;align-items: center;">
            <div style="width:40%;position: relative;">
              <el-image :src="$pic(item.url)" alt="" :preview-src-list="$picList(bannerList)"></el-image>
              <div class="del">
                <i class="el-icon-delete del" @click="delBanner(i)"></i>
              </div>
            </div>
            <div style="width:40%" class="margin-left">
              <el-select v-model="item.linkpage" style="width:100%" placeholder="请选择banner链接页面，非必选">
                <el-option v-for="(page,pageindex) in pageList" :key="pageindex" :label="page.title" :value="page.value"></el-option>
              </el-select>
            </div>
          </li>
        </draggable>
      </div>
      <div style="width:100;line-height: 50px;border:1px solid #ebeef5;margin-bottom:10px;">暂无数据</div>
    </div>
    <el-upload style='align-self:center' :action="HOST+'/index/uploadFile?name=banner'" name="banner" :data="uploadData" :auto-upload="true" :on-success="showRes" :show-file-list="false" :before-upload='showloading' class="margin-bottom">
      <el-button type="primary" plain>
        <i class="el-icon-upload el-icon--rihgt"></i>上传banner图
      </el-button>
    </el-upload>
    <el-button type="sucess" @click="saveBanner">保存</el-button>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
export default {
  components: {
    draggable,
  },
  data() {
    return {
      bannerList: [],
      loading: false,
      pageList:[],
      uploadData: {
        token: sessionStorage.getItem("token"),
      },
    };
  },
  methods: {
    showloading() {
      this.loading = true;
    },
    getsetting() {
      this.$axios({
        url: this.HOST + "/setting/getBanner",
        method: "post",
        data: {},
      }).then((res) => {
        if (res.status == 1) {
          this.bannerList = res.list;
          this.pageList = res.pagelist
        }
      });
    },
    delBanner(i) {
      this.$axios({
        url:this.HOST+'/setting/deleteBanner',
        method:'post',
        data:{
          url:this.bannerList[i].url,
          sort:this.bannerList[i].sort
        }
      }).then(res=>{
        if(res.status == 1){
          this.bannerList.splice(i, 1);
        }
      })
    },
    saveBanner() {
      this.loading = true;
      this.$axios({
        url: this.HOST + '/setting/setBanner',
        method: 'post',
        data: {
          list: this.bannerList
        }
      }).then(res => {
        this.loading = false;
        if (res.status == 1) {
          this.$message({
            type: 'success',
            message: res.info
          })
        }
      })
    },
    showRes(res) {
      this.loading = false;
      if (res.status == 1) {
        this.bannerList.push({
          url:res.file,
          linkpage:'',
        })
      } else {
        this.$message({
          type: "error",
          message: res.info,
        });
      }
    },
    setstatus(item){
      let status = item.status == 1?0:1
      this.$axios({
        url:this.HOST+'/setting/setBannerStatus',
        method:'post',
        data:{
          id:item.id,
          status:status
        }
      }).then(res=>{
        if(res.status == 1){
          this.$message({
            type:'success',
            message:res.info
          })
          item.status = status;
        }
      })
    }
  },
  created: function () {
    this.getsetting();
  },
};
</script>