<template>
  <div>
    <div class="maintitle">操作员组列表</div>
    <el-divider></el-divider>
    <el-row class="text-left">
      <el-button type="success" size="mini" @click="addDiv=true">添加操作员组</el-button>
    </el-row>
    <el-table :data="list" row-key="menuid" border>
      <el-table-column prop="title" label="操作员组名"></el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <span :class="scope.row.status==1?'text-success':'text-danger'">{{scope.row.status==1?'正常':'停用'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width='150'>
        <template slot-scope="scope">
          <el-tooltip class="item margin-right" content="修改" placement="top-start">
            <i class="el-icon-edit cursor-pointer" @click="mdfy(scope)"></i>
          </el-tooltip>
          <el-tooltip class="item margin-right" :content="scope.row.status==1?'停用':'启用'" placement="top-start">
            <i class="cursor-pointer" :class="scope.row.status==1?'el-icon-turn-off':'el-icon-open'" @click="setStatus(scope)"></i>
          </el-tooltip>
          <el-tooltip class="item margin-right" content="删除" placement="top-start">
            <i class="el-icon-delete cursor-pointer" @click="del(scope)"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :visible.sync="addDiv" :show-close="false" :close-on-click-modal="false" @opened="initinfo" @closed="resetFormInfo('groupinfo')">
      <el-form :model="groupinfo" :rules="rules" ref="groupinfo">
        <el-form-item prop="groupid" class="hidden">
          <el-input v-model="groupinfo.groupid" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="操作员组名" :label-width="formLabelWidth" prop="title">
          <el-input v-model="groupinfo.title" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="权限" :label-width="formLabelWidth" prop="auth" class="text-left">
          <el-checkbox-group v-model="groupinfo.auth">
            <el-checkbox v-for="(item,index) in menus" :key="index" :label="item.menuid" :value="item.menuid" :class="item.pid==0?'toplevel':'text-info'">{{ item.title }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item :label-width="formLabelWidth" label="状态" class="text-left" prop="status">
          <el-radio-group v-model="groupinfo.status">
            <el-radio-button label="1">正常</el-radio-button>
            <el-radio-button label="0">停用</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addDiv = false">取 消</el-button>
        <el-button type="primary" @click="submdfy">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      menus: [], //所有可用菜单项
      addDiv: false,
      groupinfo: {
        groupid: "",
        title: "",
        auth: [],
        status: 1,
      },
      crntscope: [],
      rules: {}
    };
  },
  methods: {
    getlist() {
      this.$axios({
        url: this.HOST + "/operator/getGroupList",
        method: "post",
        data: {}
      }).then(res => {
        if (res.status == 1) {
          this.menus = res.menus;
          this.list = res.list;
        }
      });
    },
    //重置菜单编辑对话框内容
    resetFormInfo(formName) {
      this.$refs[formName].resetFields();
      this.crntscope = "";
    },
    mdfy(scope) {
      this.crntscope = scope;
      this.addDiv = true;
    },
    initinfo() {
      if (this.crntscope != "") {
        for (let item in this.groupinfo) {
          this.groupinfo[item] = this.crntscope.row[item];
        }
      }
    },
    submdfy() {
      this.$axios({
        url: this.HOST + "/operator/addGroup",
        method: "post",
        data: {
          info: this.groupinfo
        }
      }).then(res => {
        if (res.status == 1) {
          this.addDiv = false;
          this.getlist();
        }
      });
    },
    formatStatus(row, column) {
      return row.status == 1 ? '正常' : '停用';
    },
    setStatus(scope) {
      let status = scope.row.status == 1 ? 0 : 1;
      this.$axios({
        url: this.HOST + '/menu/setstatus',
        method: 'post',
        data: {
          info: {
            menuid: scope.row.groupid,
            status: status
          }
        }
      }).then(res => {
        if (res.status == 1) {
          scope.row.status = status;
          this.$message({
            showClose: true,
            type: 'info',
            message: '操作成功'
          })
        }
      });
    },
    del(scope) {
      this.$confirm(
        "确定要修改\"" + scope.row.title + "\"操作员组吗？本操作不可恢复！！！",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        this.$axios({
          url: this.HOST + "/operator/delGroup",
          method: "post",
          data: {
            groupid: scope.row.groupid,
          },
        }).then((res) => {
          if (res.status == 1) {
            this.list.splice(scope.$index, 1)
            this.$message({
              showClose: true,
              type: "info",
              message: "操作成功",
            });
          }
        });
      });
    },
  },
  created: function () {
    this.getlist();
  }
};
</script>
<style>
.toplevel {
  width: 100%;
  font-weight: bold;
}

.toplevel > .el-checkbox__label {
  font-size: 16px;
}
</style>