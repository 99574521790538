<template>
  <div>
    <div class="maintitle">{{$route.meta.title}}</div>
    <el-divider></el-divider>
    <el-row class="text-left">
      <el-form :model="taskInfo">
        <el-form-item label="日期" :label-width="formLabelWidth" class="text-left">
          <el-date-picker v-model="taskInfo.time" type="date" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="客户名称" :label-width="formLabelWidth" class="text-left">
          <el-select v-model="taskInfo.companyid" placeholder="请选择" filterable allow-create default-first-option style="width:300px">
            <el-option v-for="(item,index) in companyList" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="业务类型" :label-width="formLabelWidth" class="text-left">
          <el-select v-model="taskInfo.typeid" placeholder="请选择" filterable allow-create default-first-option>
            <el-option v-for="(item,index) in typeList" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="子业务类型" :label-width="formLabelWidth" class="text-left" v-if="taskInfo.typeid!=''">
          <el-select v-model="taskInfo.childtypeid" placeholder="请选择" filterable allow-create default-first-option>
            <el-option v-for="(item,index) in childtypeList" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" :label-width="formLabelWidth" class="text-left" @change="crntnum=0">
          <el-select v-model="taskInfo.status" placeholder="请选择工单状态">
            <el-option label="待制作" :value="1"></el-option>
            <el-option label="制作中" :value="2"></el-option>
            <el-option label="已完成" :value="3"></el-option>
            <el-option label="已转出" :value="4"></el-option>
            <el-option label="已交付" :value="5"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="可制作数量" :label-width="formLabelWidth" v-if="taskInfo.status == 2">
          <el-input v-model="taskInfo.crntnum" placeholder="请输入当前可制作数量"></el-input>
        </el-form-item>
        <el-form-item label="日期" :label-width="formLabelWidth" class="text-left" v-if="taskInfo.status==5">
          <el-date-picker v-model="taskInfo.finishtime" type="date" placeholder="选择交付日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="客服" :label-width="formLabelWidth" class="text-left">
          <el-select v-model="taskInfo.kefuid" placeholder="请选择" filterable allow-create default-first-option>
            <el-option v-for="(item,index) in kefuList" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="业务" :label-width="formLabelWidth" class="text-left">
          <el-select v-model="taskInfo.yewuid" placeholder="请选择" filterable allow-create default-first-option multiple>
            <el-option v-for="(item,index) in yewuList" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="工作量单位" :label-width="formLabelWidth" v-if="isNaN(taskInfo.childtypeid)">
          <el-input v-model="taskInfo.unit" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="单价" :label-width="formLabelWidth">
          <el-input v-model="taskInfo.price" auto-complete="off" type="number">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item label="总工作量" :label-width="formLabelWidth">
          <el-input v-model="taskInfo.total" auto-complete="off" type="number">
            <template slot="append">{{ typeUnit }}</template>
          </el-input>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row>
      <el-button @click="$router.back()">返回</el-button>
      <el-button @click="submit">提交</el-button>
    </el-row>
  </div>
</template>
    
    <script>
export default {
  data() {
    return {
      taskInfo: {
        id: '',
        companyid: '',
        typeid: '',
        kefuid: '',
        yewuid:[],
        childtypeid: '',
        price: '',
        total: '',
        crntnum:0,
        status:1,
        time: this.getDate()
      },
      typeList: [],
      companyList: [],
      kefuList: [],
      yewuList: [],
    };
  },
  computed: {
    childtypeList() {
      for (let item in this.typeList) {
        if (this.typeList[item].id == this.taskInfo.typeid) return this.typeList[item].children;
      }
    },
    typeUnit() {
      for (let item in this.childtypeList) {
        if (this.childtypeList[item].id == this.taskInfo.childtypeid) return this.childtypeList[item].unit
      }
    },
  },
  methods: {
    handleSizeChange(size) {
      this.pagesize = size;
      this.page = 1;
      this.getlist();
    },
    handleCurrentChange(p) {
      this.page = p;
      this.getlist();
    },
    getInfo() {
      this.$axios({
        url: this.HOST + "/index/getCommonInfo",
        method: "post",
        data: {
          info: ['typeList', 'companyList', 'kefuList','yewuList']
        }
      }).then(res => {
        if (res.status == 1) {
          this.companyList = res.companyList;
          this.typeList = res.typeList;
          this.kefuList = res.kefuList;
          this.yewuList = res.yewuList;
        }
      });
    },
    submit() {
      this.$axios({
        url: this.HOST + "/task/add",
        method: "post",
        data: {
          info: this.taskInfo
        }
      }).then(res => {
        if (res.status == 1) {
          this.$message({
            type: 'success',
            message: res.info
          })
          this.$router.go(-1);
        }
      });
    }
  },
  created: function () {
    this.getInfo();
    if (this.$route.params.id) {
      this.$axios({
        url: this.HOST + "/task/getInfo",
        method: "post",
        data: {
          id: this.$route.params.id
        }
      }).then(res => {
        if (res.status == 1) {
          this.taskInfo = res.info;
        }
      });
    }
  }
};
    </script>
    <style>
.toplevel {
  width: 100%;
  font-weight: bold;
}
.toplevel > .el-checkbox__label {
  font-size: 16px;
}
</style>